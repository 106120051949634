.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.ant-select {
  width: 290px;
}

.ant-selectMatrimony {
  width: 285px;
}

.ant-picker {
  width: 290px;
}

.mahasabhaSelect {
  width: 310px;
}

.mahasabhaInput {
  width: 310px;
}

.mahasabhaCountryState {
  width: 230px;
}
.list-group > list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.card-body p {
  font-family: arimo;
  color: #505050;
  line-height: 28px;
  letter-spacing: 0.3;
  font-size: 15px;
}

.memberImg {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 268px;
}

.memberImg:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.teamMember {
  background: none repeat scroll 0 0 #ffffff;
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #e47257;
  height: 420px;
}

.teamMemberDetails {
  float: left;
  font-family: montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0 20px 10px;
  width: 100%;
}
.teamMemberName {
  color: #303030;
  float: left;
  font-size: 18px;
  margin: 0;
  padding: 10px 20px 5px;
  width: 100%;
}

.btnText {
  float: left;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 200px;
  font-size: 12px;
}

.redColor {
  color: red !important;
}

.rounded {
  border: 1px solid black;
}

.widget {
  float: left;
  width: 100%;
}

/*Landing Page*/
.galleryImages {
  display: inline-flex;
  padding-left: 8em;
  padding-right: 8em;
}

.eventdate {
  font-family: arimo;
  font-size: 12px;
  left: 0;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  top: 4%;
  width: 125px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.eventbar {
  float: left;
  width: 100%;
}

.eventDesc {
  text-align: left;
  font-size: 18px;
  margin-top: 0px;
  padding-top: 0px;
  width: 100%;
}
/*Landing Page*/

/*About Us*/
.title.style3 h2 span {
  color: #e47257;
  font-weight: 600;
  font-size: 28px;
}
.title.style3 > h2 span:before {
  background-color: #e47257;
}
/*About Us*/

/*Photo Gallery*/
.photoGalleryImages {
  display: inline-flex;
  padding-left: 25em;
  padding-right: 20em;
}
/*Photo Gallery*/

/*Janganna*/
.membersFont {
  font-size: 14px;
  font-family: arimo;
  padding: 8px;
}
/*Janganna*/

/*Add Marriage*/
.addMarriageP {
  text-align: center;
  color: red;
  font-weight: bold;
  font-size: 15px;
  font-family: arimo;
}

.addMarriageSubP {
  text-align: center;
  color: red;
  font-size: 15px;
  font-family: arimo;
}

.bankDetailsFont p{
  font-size: 15px;
  font-family: arimo;
  color:#505050;
  font-weight: bold;
}
/*Add Marriage*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
